/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "css/pages/mint/MintMetadataForm.module.css";
import FormTextInput from "components/input/FormTextInput";
import FontClass from "types/enums/FontClass";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import useSolanaContext from "hooks/useSolanaContext";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Maybe } from "types/UtilityTypes";

import invariant from "tiny-invariant";
import mintNft from "utils/metaplex/mintNft";
import logIfNotProd from "utils/logIfNotProd";
import useRoyaltiesReducer, {
  isRoyaltiesValid,
} from "hooks/useRoyaltiesReducer";
import MintMetadataRoyaltiesInput from "components/pages/mint/MintMetadataRoyaltiesInput";
import { useEffect, useState } from "react";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import ErrorMessage from "types/enums/ErrorMessage";
import getFileExt from "utils/getFileExt";

const schema = yup.object().shape({
  collectionFamily: yup.string().required(),
  collectionName: yup.string().required(),
  description: yup.string().optional(),
  maxSupply: yup.string().matches(/^\d*$/, "Not a number"),
  name: yup.string().required(),
  sellerFeeBasisPoints: yup.number().integer().required(),
  symbol: yup.string().required(),
});

type FormData = {
  collectionFamily: string;
  collectionName: string;
  description: string;
  maxSupply: string;
  name: string;
  sellerFeeBasisPoints: number;
  symbol: string;
};

type Props = { file: Maybe<File> };

export default function MintMetadataForm({ file }: Props): JSX.Element {
  const { connection } = useSolanaContext();
  const wallet = useAnchorWallet();
  const [royalties, dispatchRoyalties] = useRoyaltiesReducer();
  // We only want the error message to be shown after the user tries to submit
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      collectionFamily: "",
      collectionName: "",
      description: "",
      maxSupply: "",
      name: "",
      sellerFeeBasisPoints: 0,
      symbol: "",
    },
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const setErrorMessageHelper = () => {
    if (wallet == null) {
      setErrorMessage(ErrorMessage.ConnectWallet);
      return true;
    }

    if (file == null) {
      setErrorMessage(ErrorMessage.ChooseFile);
      return true;
    }

    if (!isValid || !isRoyaltiesValid(royalties)) {
      setErrorMessage(ErrorMessage.InputsValid);
      return true;
    }

    setErrorMessage(null);
    return false;
  };

  // We want the error message to be in sync with the inputs
  useEffect(() => {
    if (file != null && errorMessage === ErrorMessage.ChooseFile) {
      setErrorMessageHelper();
    }
  }, [errorMessage, file]);
  useEffect(() => {
    if (wallet != null && errorMessage === ErrorMessage.ConnectWallet) {
      setErrorMessageHelper();
    }
  }, [errorMessage, wallet]);
  useEffect(() => {
    if (showErrors) {
      setErrorMessageHelper();
    }
  }, [errorMessage, isRoyaltiesValid(royalties), showErrors]);
  useEffect(() => {
    if (showErrors) {
      setErrorMessageHelper();
    }
  }, [isValid, showErrors]);

  const onError = async () => {
    setShowErrors(true);
    setErrorMessageHelper();
  };

  const onSubmit = async (values: FormData) => {
    logIfNotProd(values);

    setShowErrors(true);
    const hasError = setErrorMessageHelper();
    if (hasError) {
      return;
    }

    invariant(wallet != null && file != null);

    const results = await mintNft(
      connection,
      wallet,
      file,
      {
        collection: {
          family: values.collectionFamily,
          name: values.collectionName,
        },
        creators: royalties,
        description: values.description,
        image: `image.${getFileExt(file)}`,
        name: values.name,
        sellerFeeBasisPoints: values.sellerFeeBasisPoints,
        symbol: values.symbol,
      },
      values.maxSupply.length === 0 ? null : Number(values.maxSupply)
    );
    if (results != null) {
      logIfNotProd("nft created, mint account", results.mintAccount.toString());
      logIfNotProd(
        "nft created, metadata account",
        results.metadataAccount.toString()
      );
    }
  };

  logIfNotProd("errors", errors);
  logIfNotProd("isValid", isValid);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
      <div className={styles.inputs}>
        <FormTextInput
          hasError={showErrors && errors.name != null}
          label="Name"
          labelFontClass={FontClass.Body1}
          registerResult={register("name")}
        />
        <FormTextInput
          label="Description (Optional)"
          labelFontClass={FontClass.Body1}
          registerResult={register("description")}
        />
        <FormTextInput
          hasError={showErrors && errors.collectionName != null}
          label="Collection Name"
          labelFontClass={FontClass.Body1}
          registerResult={register("collectionName")}
        />
        <FormTextInput
          hasError={showErrors && errors.collectionFamily != null}
          label="Collection Family"
          labelFontClass={FontClass.Body1}
          registerResult={register("collectionFamily")}
        />
        <FormTextInput
          hasError={showErrors && errors.symbol != null}
          label="Symbol"
          labelFontClass={FontClass.Body1}
          registerResult={register("symbol")}
        />
        <FormTextInput
          hasError={showErrors && errors.maxSupply != null}
          label="Max Supply (Optional)"
          labelFontClass={FontClass.Body1}
          registerResult={register("maxSupply")}
          subLabel="Set it to 0 for 1/1 NFTs. Leave blank for unlimited supply."
        />
        <FormTextInput
          hasError={showErrors && errors.sellerFeeBasisPoints != null}
          label="Seller Fee Basis Points"
          labelFontClass={FontClass.Body1}
          registerResult={register("sellerFeeBasisPoints")}
          subLabel="How much royalties creators receive for secondary sales, in basis points"
        />
        <MintMetadataRoyaltiesInput
          dispatchRoyalties={dispatchRoyalties}
          royalties={royalties}
          showErrors={showErrors}
        />
      </div>
      <ButtonWithText
        buttonTheme={ButtonTheme.Navy}
        className={styles.mintButton}
        fontClass={FontClass.Body1}
        type="submit"
      >
        Mint
      </ButtonWithText>
      {errorMessage != null && (
        <Body1 className={styles.errorMessage} colorClass={ColorClass.Error}>
          {errorMessage}
        </Body1>
      )}
    </form>
  );
}
