import { actions } from "@metaplex/js";
import { Connection, Keypair, SendOptions, Transaction } from "@solana/web3.js";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Maybe } from "types/UtilityTypes";
import { notify } from "components/toast/notifications";
import parseErrorMessage from "utils/solana/parseErrorMessage";

export default async function sendTransaction(args: {
  connection: Connection;
  wallet: AnchorWallet;
  txs: Array<Transaction>;
  signers: Array<Keypair>;
  options?: SendOptions;
}): Promise<Maybe<string>> {
  try {
    return await actions.sendTransaction(args);
  } catch (e) {
    notify({
      message: "Transaction error",
      description: parseErrorMessage((e as Error).message),
      type: "error",
    });

    return null;
  }
}
