import Header from "components/header/Header";
import PlusSquareIcon from "components/icons/PlusSquareIcon";
import DropzoneBlankContent from "components/input/DropzoneBlankContent";
import Body1 from "components/text/Body1";
import styles from "css/pages/mint/MintPage.module.css";
import { useState } from "react";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import { Maybe, MaybeUndef } from "types/UtilityTypes";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import GenericDropzone from "components/input/GenericDropzone";
import Dimensions from "types/Dimensions";
import joinClasses from "utils/joinClasses";
import GlobalClass from "types/enums/GlobalClass";
import MintMetadataForm from "components/pages/mint/MintMetadataForm";
import Header1 from "components/text/Header1";

const PIXEL_ART_MAX_SIZE = 256;

function DropzoneContent({
  acceptedFiles,
  imageDimensions,
}: {
  acceptedFiles: Array<File>;
  imageDimensions?: MaybeUndef<Dimensions>;
}): JSX.Element {
  const minDimension =
    imageDimensions != null
      ? Math.min(imageDimensions.width, imageDimensions.height)
      : 0;

  const withFileContent =
    acceptedFiles.length !== 0 ? (
      <div className={styles.content}>
        <img
          className={joinClasses(
            styles.photo,
            minDimension <= PIXEL_ART_MAX_SIZE
              ? GlobalClass.PixelArtImage
              : null
          )}
          src={URL.createObjectURL(acceptedFiles[0])}
        />
        <Body1 className={styles.description} colorClass={ColorClass.Purple}>
          Choose a different photo
        </Body1>
      </div>
    ) : null;

  if (withFileContent != null) {
    return withFileContent;
  }

  return (
    <DropzoneBlankContent
      colorClass={ColorClass.Purple}
      icon={<PlusSquareIcon colorValue={ColorValue.Purple} />}
    />
  );
}

export default function MintPage(): JSX.Element {
  const [file, setFile] = useState<Maybe<File>>(null);

  return (
    <div>
      <Header />
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <Header1 colorClass={ColorClass.Navy}>
            Mint Master Edition NFT
          </Header1>
          <GenericDropzone
            Component={DropzoneContent}
            className={styles.dropzone}
            onDropAccepted={async (files) => {
              setFile(files[0]);
            }}
          />
          <div className={styles.form}>
            <MintMetadataForm file={file} />
          </div>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
