import { Route, Routes as RoutesImport } from "react-router-dom";
import MintPage from "components/pages/mint/MintPage";
import MintStandardPage from "components/pages/mint-standard/MintStandardPage";
import ViewHoldersPage from "components/pages/view-holders/ViewHoldersPage";
import AirdropPage from "components/pages/airdrop/AirdropPage";

// TODO: figure out how to set page titles
export default function Routes(): JSX.Element {
  return (
    <RoutesImport>
      <Route path="/" element={<MintPage />} />
      <Route path="/airdrop" element={<AirdropPage />} />
      <Route path="/mint" element={<MintPage />} />
      <Route path="/mint-standard" element={<MintStandardPage />} />
      <Route path="/view-holders" element={<ViewHoldersPage />} />
    </RoutesImport>
  );
}
