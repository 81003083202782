import { PublicKey } from "@solana/web3.js";

export default function isPublicKey(str: string): boolean {
  try {
    const _publicKey = new PublicKey(str);
    return true;
  } catch {
    return false;
  }
}
