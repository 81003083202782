/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import { notification } from "antd";
import type { ArgsProps, NotificationInstance } from "antd/lib/notification";
import { ReactNode } from "react";
import ReactDOMServer from "react-dom/server";
import styles from "css/toast/notifications.module.css";
import ExternalLink from "components/link/ExternalLink";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

interface INotifyArgs {
  description?: ReactNode;
  duration?: number;
  message?: string;
  network?: WalletAdapterNetwork;
  placement?: ArgsProps["placement"];
  txid?: string;
  txids?: string[];
  type?: keyof NotificationInstance;
}

export const InsufficientSOLMessage: INotifyArgs = {
  message: "Insufficient SOL balance",
  description:
    "Your account does not have enough SOL to pay for this transaction. Please fund your account.",
  type: "error",
};

export function notify({
  duration = 7,
  message,
  description,
  network,
  txid,
  txids,
  type = "info",
  placement = "bottomLeft",
}: INotifyArgs): void {
  // log for Sentry and other debug purposes
  const logLevel =
    type === "warning" ? "warn" : type === "error" ? "error" : "info";
  // eslint-disable-next-line no-console
  console[logLevel](
    `Notify: ${message ?? "<no message>"} -- ${
      typeof description === "string"
        ? description
        : // eslint-disable-next-line react/jsx-no-useless-fragment
          ReactDOMServer.renderToStaticMarkup(<>{description}</>)
    }`,
    {
      env: network,
      txid,
      txids,
      type,
    }
  );

  if (txids?.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    txid = txids[0];
  }
  if (txid) {
    description = (
      <div>
        View Transaction:{" "}
        <ExternalLink
          href={`https://explorer.solana.com/tx/${txid}?cluster=${
            network?.toString() ?? ""
          }`}
        >
          {txid.slice(0, 8)}...{txid.slice(txid.length - 8)}
        </ExternalLink>
      </div>
    );
  } else if (txids) {
    description = (
      <div>
        View Transactions:{" "}
        <div className={styles.txContainer}>
          {txids.map((txid, i) => (
            <ExternalLink
              key={i}
              href={`https://explorer.solana.com/tx/${txid}?cluster=${
                network?.toString() ?? ""
              }`}
            >
              [{i + 1}]
            </ExternalLink>
          ))}
        </div>
      </div>
    );
  }

  notification[type]({
    message,
    description,
    placement,
    duration,
  });
}
