import ButtonWithText from "components/buttons/ButtonWithText";
import ChooseNetworkModal from "components/modal/ChooseNetworkModal";
import { useState } from "react";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import styles from "css/buttons/ChooseNetworkButton.module.css";
import useSolanaContext from "hooks/useSolanaContext";

export default function ChooseNetworkButton(): JSX.Element {
  const [isModalShown, setIsModalShown] = useState(false);
  const { network } = useSolanaContext();

  return (
    <>
      <ChooseNetworkModal
        isShown={isModalShown}
        onHide={() => setIsModalShown(false)}
      />
      <ButtonWithText
        buttonTheme={ButtonTheme.Navy}
        className={styles.button}
        fontClass={FontClass.Body2}
        onClick={() => setIsModalShown(true)}
      >
        {network}
      </ButtonWithText>
    </>
  );
}
