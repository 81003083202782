import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

export default function getRpcHostFromNetwork(
  network: WalletAdapterNetwork
): string {
  switch (network) {
    case WalletAdapterNetwork.Devnet:
      return "https://dry-proud-tree.solana-devnet.quiknode.pro/371933be8a802820f2bf9943f509145b797d14e6/";
    case WalletAdapterNetwork.Mainnet:
      return "https://patient-proud-dew.solana-mainnet.quiknode.pro/0c5b5dfc389f144e912274604daa0a401b2bf367/";
    // return "https://michael.rpcpool.com/";
    // return "https://ssc-dao.genesysgo.net/";
    case WalletAdapterNetwork.Testnet:
      return "https://api.testnet.solana.com";
    default:
      throw new Error("should not reach");
  }
}
