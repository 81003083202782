/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { Account, Connection, programs, Wallet } from "@metaplex/js";
import { Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import BN from "bn.js";
import { notify } from "components/toast/notifications";
import { SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID } from "constants/SolanaConstants";
import { Maybe } from "types/UtilityTypes";
import prepareTokenAccountAndMintTx from "utils/metaplex/prepareTokenAccountAndMintTx";
import pluralize from "utils/pluralize";
import { range } from "utils/range";
import sendTransaction from "utils/solana/sendTransaction";

interface MintEditionFromMasterParams {
  connection: Connection;
  wallet: Wallet;
  masterEditionMint: PublicKey;
  updateAuthority?: PublicKey;
}
interface Info {
  mint: PublicKey;
  metadata: PublicKey;
  edition: PublicKey;
}

async function getTx(
  {
    connection,
    wallet,
    masterEditionMint,
    updateAuthority,
  }: MintEditionFromMasterParams,
  increment = 1
) {
  const masterPDA = await programs.metadata.MasterEdition.getPDA(
    masterEditionMint
  );
  const masterMetaPDA = await programs.metadata.Metadata.getPDA(
    masterEditionMint
  );
  const masterInfo = await Account.getInfo(connection, masterPDA);
  const masterData = new programs.metadata.MasterEdition(masterPDA, masterInfo)
    .data;

  // take the current outstanding supply and increment
  const editionValue = masterData.supply.add(new BN(increment));

  const { mint, createMintTx, createAssociatedTokenAccountTx, mintToTx } =
    await prepareTokenAccountAndMintTx(connection, wallet.publicKey);

  const tokenAccount = await Token.getAssociatedTokenAddress(
    SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    masterEditionMint,
    wallet.publicKey
  );

  const metadataPDA = await programs.metadata.Metadata.getPDA(mint.publicKey);
  const editionMarker = await programs.metadata.EditionMarker.getPDA(
    masterEditionMint,
    editionValue
  );
  const editionPDA = await programs.metadata.Edition.getPDA(mint.publicKey);

  const newEditionFromMasterTx =
    new programs.metadata.MintNewEditionFromMasterEditionViaToken(
      { feePayer: wallet.publicKey },
      {
        edition: editionPDA, // empty, created inside program
        metadata: metadataPDA, // empty, created inside program
        updateAuthority: updateAuthority ?? wallet.publicKey,
        mint: mint.publicKey,
        mintAuthority: wallet.publicKey,
        masterEdition: masterPDA,
        masterMetadata: masterMetaPDA,
        editionMarker, // empty if this is the 1st limited edition being created
        tokenOwner: wallet.publicKey,
        tokenAccount,
        editionValue,
      }
    );

  return {
    edition: editionPDA,
    metadata: metadataPDA,
    mint,
    tx: programs.Transaction.fromCombined([
      createMintTx,
      createAssociatedTokenAccountTx,
      mintToTx,
      newEditionFromMasterTx,
    ]),
  };
}

export default async function mintStandardEditionNft(
  args: MintEditionFromMasterParams,
  amount: number
): Promise<
  Maybe<{
    info: Array<Info>;
    txids: Array<string>;
  }>
> {
  const info = await Promise.all(
    range(amount).map((increment) => getTx(args, increment + 1))
  );
  const txs = info.map((val) => val.tx);

  const txids: Array<string> = [];

  for (const [index, tx] of txs.entries()) {
    const txid = await sendTransaction({
      connection: args.connection,
      wallet: args.wallet,
      txs: [tx],
      signers: [info[index].mint],
      options: {
        preflightCommitment: "confirmed",
      },
    });

    if (txid == null) {
      break;
    }

    txids.push(txid);
    notify({
      message: `Minted 1 standard edition NFT, mint address is ${info[
        index
      ].mint.publicKey.toString()}`,
      txid,
    });
    await args.connection.getParsedConfirmedTransaction(txid, "confirmed");
  }

  const infoToReturn = info.map((val) => ({
    mint: val.mint.publicKey,
    edition: val.edition,
    metadata: val.metadata,
  }));

  return { info: infoToReturn.slice(0, txids.length), txids };
}
