import { Connection, PublicKey } from "@solana/web3.js";
import { Maybe } from "types/UtilityTypes";
import getNftMintTokenAccount from "utils/solana/getNftMintTokenAccount";

/**
 * Returns the owner of the mint's token account.
 *
 * Mint should correspond to an NFT.
 */
export default async function getNftMintOwner(
  connection: Connection,
  mintAddress: PublicKey
): Promise<Maybe<PublicKey>> {
  const tokenAccount = await getNftMintTokenAccount(connection, mintAddress);
  return tokenAccount?.data?.owner ?? null;
}
