import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import ButtonWithText from "components/buttons/ButtonWithText";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Header from "components/header/Header";
import TextInput from "components/input/TextInput";
import LoadingSpinner from "components/loading/LoadingSpinner";
import AirdropRow from "components/pages/airdrop/AirdropRow";
import Body1 from "components/text/Body1";
import styles from "css/pages/airdrop/AirdropPage.module.css";
import useSolanaContext from "hooks/useSolanaContext";
import { useState } from "react";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import ErrorMessage from "types/enums/ErrorMessage";
import FontClass from "types/enums/FontClass";
import { Maybe } from "types/UtilityTypes";
import getOwnedMints from "utils/solana/getOwnedMints";

export default function AirdropPage(): JSX.Element {
  const { connection } = useSolanaContext();
  const wallet = useAnchorWallet();
  const [excludeAddresses, setExcludeAddresses] = useState("");
  const [mintAddresses, setMintAddresses] = useState<Array<PublicKey>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  return (
    <div>
      <Header />
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <div className={styles.form}>
            <Body1>
              This will find all the token mints that you own, and let you
              easily send them to people. It is intended to be used to send a
              bunch of standard edition NFTs.
            </Body1>
            <div className={styles.input}>
              <TextInput
                label="Addresses to exclude (comma-separated) (optional)"
                onChange={(val) => {
                  setExcludeAddresses(val);
                  setErrorMessage(null);
                }}
                placeholder="E.g. master edition mints"
                value={excludeAddresses}
              />
            </div>
            <ButtonWithText
              buttonTheme={ButtonTheme.Navy}
              className={styles.submitButton}
              fontClass={FontClass.Body1}
              onClick={async () => {
                if (wallet == null) {
                  setErrorMessage(ErrorMessage.ConnectWallet);
                  return;
                }

                setIsLoading(true);
                setMintAddresses([]);

                const exclude = JSON.parse(`[${excludeAddresses}]`);
                const mints = await getOwnedMints(
                  connection,
                  wallet.publicKey.toString(),
                  exclude
                );
                setIsLoading(false);
                setMintAddresses(mints ?? []);
              }}
            >
              {isLoading ? (
                <LoadingSpinner colorValue={ColorValue.White} />
              ) : (
                "Submit"
              )}
            </ButtonWithText>
          </div>
          {errorMessage != null && (
            <Body1
              className={styles.errorMessage}
              colorClass={ColorClass.Error}
            >
              {errorMessage}
            </Body1>
          )}
          <div className={styles.rows}>
            {mintAddresses.map((key) => (
              <AirdropRow key={key.toString()} mintAddress={key} />
            ))}
          </div>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
