/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "css/pages/mint-standard/MintStandardMetadataForm.module.css";
import FormTextInput from "components/input/FormTextInput";
import FontClass from "types/enums/FontClass";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import useSolanaContext from "hooks/useSolanaContext";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Maybe } from "types/UtilityTypes";

import invariant from "tiny-invariant";
import logIfNotProd from "utils/logIfNotProd";
import { useEffect, useState } from "react";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import ErrorMessage from "types/enums/ErrorMessage";
import mintStandardEditionNft from "utils/metaplex/mintStandardEditionNft";
import isPublicKey from "utils/isPublicKey";
import { PublicKey } from "@solana/web3.js";
import isEmptyStringOrNull from "utils/isEmptyStringOrNull";

const schema = yup.object().shape({
  amount: yup.number().integer().optional(),
  mintAddress: yup
    .string()
    .required()
    .test(
      "isPublicKey",
      "Not a PublicKey",
      (val) => val != null && isPublicKey(val)
    ),
  updateAuthority: yup
    .string()
    .optional()
    .test(
      "isPublicKey",
      "Not a PublicKey",
      (val) => val == null || val === "" || isPublicKey(val)
    ),
});

type FormData = {
  amount: string;
  mintAddress: string;
  updateAuthority: string;
};

export default function MintStandardMetadataForm(): JSX.Element {
  const { connection } = useSolanaContext();
  const wallet = useAnchorWallet();
  // We only want the error message to be shown after the user tries to submit
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      amount: "1",
      mintAddress: "",
      updateAuthority: "",
    },
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const setErrorMessageHelper = () => {
    if (wallet == null) {
      setErrorMessage(ErrorMessage.ConnectWallet);
      return true;
    }

    if (!isValid) {
      setErrorMessage(ErrorMessage.InputsValid);
      return true;
    }

    setErrorMessage(null);
    return false;
  };

  // We want the error message to be in sync with the inputs
  useEffect(() => {
    if (wallet != null && errorMessage === ErrorMessage.ConnectWallet) {
      setErrorMessageHelper();
    }
  }, [errorMessage, wallet]);
  useEffect(() => {
    if (showErrors) {
      setErrorMessageHelper();
    }
  }, [isValid, showErrors]);

  const onError = async () => {
    setShowErrors(true);
    setErrorMessageHelper();
  };

  const onSubmit = async (values: FormData) => {
    logIfNotProd(values);

    setShowErrors(true);
    const hasError = setErrorMessageHelper();
    if (hasError) {
      return;
    }

    invariant(wallet != null);

    const response = await mintStandardEditionNft(
      {
        connection,
        wallet,
        masterEditionMint: new PublicKey(values.mintAddress),
        updateAuthority: isEmptyStringOrNull(values.updateAuthority)
          ? undefined
          : new PublicKey(values.updateAuthority),
      },
      Number(values.amount)
    );
    if (response != null) {
      response.info.forEach(({ mint }) => {
        logIfNotProd("mint", mint.toString());
      });
    }
  };

  logIfNotProd("errors", errors);
  logIfNotProd("isValid", isValid);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
      <div className={styles.inputs}>
        <FormTextInput
          hasError={showErrors && errors.mintAddress != null}
          label="Mint Address"
          labelFontClass={FontClass.Body1}
          registerResult={register("mintAddress")}
        />
        <FormTextInput
          hasError={showErrors && errors.updateAuthority != null}
          label="Update Authority Address (Optional)"
          labelFontClass={FontClass.Body1}
          registerResult={register("updateAuthority")}
        />
        <FormTextInput
          hasError={showErrors && errors.amount != null}
          label="Amount To Mint"
          labelFontClass={FontClass.Body1}
          registerResult={register("amount")}
        />
      </div>
      <ButtonWithText
        buttonTheme={ButtonTheme.Navy}
        className={styles.mintButton}
        fontClass={FontClass.Body1}
        type="submit"
      >
        Mint Standard Edition
      </ButtonWithText>
      {errorMessage != null && (
        <Body1 className={styles.errorMessage} colorClass={ColorClass.Error}>
          {errorMessage}
        </Body1>
      )}
    </form>
  );
}
