import { PublicKey } from "@solana/web3.js";
import { useState } from "react";
import styles from "css/pages/airdrop/AirdropRow.module.css";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import TextInput from "components/input/TextInput";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import shortenAddress from "utils/shortenAddress";
import PlainButton from "components/buttons/PlainButton";
import { message } from "components/toast/messages";
import { Maybe } from "types/UtilityTypes";
import isPublicKey from "utils/isPublicKey";
import transferNft from "utils/metaplex/transferNft";
import useSolanaContext from "hooks/useSolanaContext";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import ErrorMessage from "types/enums/ErrorMessage";

type Props = {
  mintAddress: PublicKey;
};

export default function AirdropRow({ mintAddress }: Props): JSX.Element {
  const { connection } = useSolanaContext();
  const wallet = useAnchorWallet();
  const [destAddress, setDestAddress] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  return (
    <div className={styles.containerOuter}>
      <div className={styles.container}>
        <PlainButton
          className={styles.address}
          onClick={() => {
            // See https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
            navigator.clipboard.writeText(mintAddress.toString());
            message({ content: "Copied to clipboard" });
          }}
        >
          <Body1 colorClass={ColorClass.Navy}>
            {shortenAddress(mintAddress.toString())}
          </Body1>
        </PlainButton>
        <TextInput
          onChange={(val) => {
            setDestAddress(val);
            setErrorMessage(null);
          }}
          placeholder="Destination"
          value={destAddress}
        />
        <ButtonWithText
          buttonTheme={ButtonTheme.Navy}
          fontClass={FontClass.Body2}
          onClick={async () => {
            if (!isPublicKey(destAddress)) {
              setErrorMessage(ErrorMessage.InvalidAddress);
              return;
            }

            if (wallet == null) {
              setErrorMessage(ErrorMessage.ConnectWallet);
              return;
            }

            setErrorMessage(null);
            await transferNft(
              connection,
              wallet,
              mintAddress,
              wallet.publicKey,
              new PublicKey(destAddress)
            );
          }}
        >
          Send
        </ButtonWithText>
      </div>
      {errorMessage && (
        <Body1 colorClass={ColorClass.Error}>{errorMessage}</Body1>
      )}
    </div>
  );
}
