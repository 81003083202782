import { ConfirmedSignatureInfo, Connection, PublicKey } from "@solana/web3.js";
import { Maybe } from "types/UtilityTypes";
import arrayLast from "utils/arrayLast";
import filterNulls from "utils/filterNulls";
import logIfNotProd from "utils/logIfNotProd";
import findMasterEditionAddress from "utils/pda/findMasterEditionAddress";

import { programs } from "@metaplex/js";
import getNftMintOwner from "utils/solana/getNftMintOwner";

/**
 * Takes the mint address of a master edition NFT, and returns the mint addresses
 * of all the standard edition NFTs.
 *
 * @param owners acts as a filter. only mint addresses with token accounts owned by one of these owners
 * will be returned.
 */
export default async function findStandardEditionMints(
  connection: Connection,
  mintAddress: PublicKey,
  owners?: Array<PublicKey>
): Promise<Maybe<Array<PublicKey>>> {
  try {
    const masterEditionAddress = await findMasterEditionAddress(mintAddress);
    const masterEdition = await programs.metadata.MasterEdition.load(
      connection,
      masterEditionAddress
    );
    logIfNotProd("Finding editions...");
    // This is the slowest part by far of the whole function.
    const editions = await masterEdition.getEditions(connection);
    const editionAddresses = editions.map((edition) =>
      edition.pubkey.toString()
    );
    logIfNotProd("editions", editionAddresses);

    const txsWithDups = filterNulls(
      await Promise.all(
        editionAddresses.map(async (edition) => {
          const signatures = await connection.getConfirmedSignaturesForAddress2(
            new PublicKey(edition)
          );
          return arrayLast(signatures);
        })
      )
    );
    const txs: Array<ConfirmedSignatureInfo> = [];
    txsWithDups.forEach((tx) => {
      if (txs.find((txInner) => txInner.signature === tx.signature)) {
        return;
      }

      txs.push(tx);
    });
    logIfNotProd("txs", txs);
    const parsedTxs = await connection.getParsedConfirmedTransactions(
      txs.map((tx) => tx.signature)
    );
    logIfNotProd("parsedTxs", parsedTxs);
    const mintAddresses = filterNulls(
      parsedTxs.map((tx) => {
        if (tx == null || tx.meta == null) {
          return null;
        }

        const { postTokenBalances, preTokenBalances } = tx.meta;
        if (postTokenBalances == null) {
          return null;
        }

        const preTokenBalanceMints =
          preTokenBalances?.map((balance) => balance.mint) ?? [];

        return filterNulls(
          postTokenBalances.map((balance) => {
            if (preTokenBalanceMints.includes(balance.mint)) {
              return null;
            }
            return new PublicKey(balance.mint);
          })
        );
      })
    ).flat();

    if (owners == null || owners.length === 0) {
      return mintAddresses;
    }

    const standardEditionOwners = filterNulls(
      await Promise.all(
        mintAddresses.map((mint) => getNftMintOwner(connection, mint))
      )
    );
    logIfNotProd("standardEditionOwners", standardEditionOwners);

    const ownerStrings = owners.map((key) => key.toString());
    return mintAddresses.filter((_, i) =>
      ownerStrings.includes(standardEditionOwners[i].toString())
    );
  } catch {
    return null;
  }
}
