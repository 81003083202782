import { Connection, PublicKey } from "@solana/web3.js";
import { Maybe } from "types/UtilityTypes";
import filterNulls from "utils/filterNulls";
import logIfNotProd from "utils/logIfNotProd";
import getMetadataAccountFromMint from "utils/solana/getMetadataAccountFromMint";
import getNftMintOwner from "utils/solana/getNftMintOwner";

import findStandardEditionMints from "utils/metaplex/findStandardEditionMints";

/**
 * Takes the mint address of a master edition NFT, and returns the holders
 * of all the standard edition NFTs, plus the holder of the master edition NFT.
 */
export default async function findHolders(
  connection: Connection,
  mintAddress: PublicKey
): Promise<Maybe<Array<PublicKey>>> {
  try {
    const [masterEditionOwner, metadataAccount] = await Promise.all([
      getNftMintOwner(connection, mintAddress),
      getMetadataAccountFromMint(connection, mintAddress),
    ]);
    if (masterEditionOwner == null || metadataAccount == null) {
      return null;
    }
    logIfNotProd("masterEditionOwner", masterEditionOwner.toString());
    logIfNotProd("metadataAccount", metadataAccount.pubkey.toString());

    const mintAddresses = await findStandardEditionMints(
      connection,
      mintAddress
    );

    logIfNotProd(
      "mintAddresses",
      mintAddresses?.map((m) => m.toString())
    );
    const standardEditionOwners = filterNulls(
      await Promise.all(
        mintAddresses?.map((mint) => getNftMintOwner(connection, mint)) ?? []
      )
    );
    logIfNotProd("standardEditionOwners", standardEditionOwners);

    return [...standardEditionOwners, masterEditionOwner];
  } catch {
    return null;
  }
}
