import InputWithQuestionAndLabel from "components/input/InputWithQuestionAndLabel";
import { RoyaltiesDispatchAction } from "hooks/useRoyaltiesReducer";
import { Dispatch } from "react";
import Royalties from "types/Royalties";
import styles from "css/pages/mint/MintMetadataRoyaltiesInput.module.css";
import Body2 from "components/text/Body2";
import ColorClass from "types/enums/ColorClass";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import isNumber from "utils/isNumber";
import CloseButton from "components/buttons/CloseButton";
import ColorValue from "types/enums/ColorValue";
import TextInput from "components/input/TextInput";
import sumArray from "utils/sumArray";
import isPublicKey from "utils/isPublicKey";

function RoyaltiesRow({
  dispatchRoyalties,
  index,
  royalties,
  showErrors,
}: {
  dispatchRoyalties: Dispatch<RoyaltiesDispatchAction>;
  index: number;
  royalties: Array<Royalties>;
  showErrors: boolean;
}): JSX.Element {
  const sum = sumArray(royalties.map(({ share }) => share));

  return (
    <div className={styles.royaltiesRow}>
      <div className={styles.walletAddress}>
        <TextInput
          hasError={showErrors && !isPublicKey(royalties[index].address)}
          onChange={(val) =>
            dispatchRoyalties({ type: "update_address", address: val, index })
          }
          value={royalties[index].address}
        />
      </div>
      <div className={styles.walletPercent}>
        <TextInput
          hasError={sum > 100 || (showErrors && sum !== 100)}
          onChange={(val) => {
            if (isNumber(val) && Number(val) > 0 && Number(val) <= 100) {
              dispatchRoyalties({
                type: "update_share",
                share: Number(val),
                index,
              });
            } else if (val.length === 0) {
              dispatchRoyalties({
                type: "update_share",
                share: 0,
                index,
              });
            }
          }}
          value={royalties[index].share.toString()}
        />
      </div>
      <CloseButton
        colorValue={ColorValue.Primary}
        isShown={index > 0}
        onClick={() => dispatchRoyalties({ type: "remove_row", index })}
      />
    </div>
  );
}

type Props = {
  dispatchRoyalties: Dispatch<RoyaltiesDispatchAction>;
  royalties: Array<Royalties>;
  showErrors: boolean;
};

export default function MintMetadataRoyaltiesInput({
  dispatchRoyalties,
  royalties,
  showErrors,
}: Props): JSX.Element {
  return (
    <InputWithQuestionAndLabel
      label="For secondary sales of these NFTs, designate the percentage of royalties going to each wallet address (usually corresponds to each creator). Percentages should add up to 100."
      question="How should the royalties be split?"
    >
      <div className={styles.royaltiesLabels}>
        <Body2
          className={styles.royaltiesLabelsLeft}
          colorClass={ColorClass.Primary}
        >
          Wallet Address
        </Body2>
        <Body2
          className={styles.royaltiesLabelsRight}
          colorClass={ColorClass.Primary}
        >
          Percentage
        </Body2>
        {/* To make labels line up with inputs */}
        <CloseButton isShown={false} />
      </div>
      <div className={styles.royalties}>
        {royalties.map((_royalties, index) => (
          <RoyaltiesRow
            dispatchRoyalties={dispatchRoyalties}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            index={index}
            royalties={royalties}
            showErrors={showErrors}
          />
        ))}
        <TextButton
          buttonTheme={TextButtonTheme.Primary}
          fontClass={FontClass.Body2Bold}
          onClick={() => dispatchRoyalties({ type: "add_row" })}
        >
          + Add another wallet address
        </TextButton>
      </div>
    </InputWithQuestionAndLabel>
  );
}
