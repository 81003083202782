import { Maybe } from "types/UtilityTypes";
import GenericModal from "components/modal/GenericModal";
import styles from "css/modal/ChooseNetworkModal.module.css";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import useSolanaContext from "hooks/useSolanaContext";

type Props = {
  isShown: boolean;
  onHide: () => void;
};

export default function ChooseNetworkModal({
  isShown,
  onHide,
}: Props): Maybe<JSX.Element> {
  const { setNetwork } = useSolanaContext();

  return (
    <GenericModal isShown={isShown} onHide={onHide} title="Choose Network">
      <div className={styles.body}>
        <ButtonWithText
          buttonTheme={ButtonTheme.Navy}
          fontClass={FontClass.Body1}
          onClick={() => {
            setNetwork(WalletAdapterNetwork.Mainnet);
            onHide();
          }}
        >
          Mainnet
        </ButtonWithText>
        <ButtonWithText
          buttonTheme={ButtonTheme.Navy}
          fontClass={FontClass.Body1}
          onClick={() => {
            setNetwork(WalletAdapterNetwork.Devnet);
            onHide();
          }}
        >
          Devnet
        </ButtonWithText>
      </div>
    </GenericModal>
  );
}
