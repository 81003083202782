import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Header from "components/header/Header";
import MintStandardMetadataForm from "components/pages/mint-standard/MintStandardMetdataForm";
import Header1 from "components/text/Header1";
import styles from "css/pages/mint-standard/MintStandardPage.module.css";
import ColorClass from "types/enums/ColorClass";

export default function MintStandardPage(): JSX.Element {
  return (
    <div>
      <Header />
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <Header1 colorClass={ColorClass.Navy}>
            Mint Standard Edition NFT
          </Header1>
          <div className={styles.form}>
            <MintStandardMetadataForm />
          </div>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
