import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";

export default async function getOwnedMints(
  connection: Connection,
  userAddress: string,
  exclude?: Array<string>
) {
  const tokenAccountsByOwner = await connection.getParsedTokenAccountsByOwner(
    new PublicKey(userAddress),
    {
      programId: TOKEN_PROGRAM_ID,
    }
  );

  const nftPotential = tokenAccountsByOwner?.value?.filter(
    (obj) =>
      obj.account.data.parsed.info.tokenAmount.uiAmount > 0 &&
      obj.account.data.parsed.info.tokenAmount.decimals === 0
  );

  const mints = nftPotential.map(
    (tokenAccount) => new PublicKey(tokenAccount.account.data.parsed.info.mint)
  );

  return mints.filter((mint) => !(exclude ?? []).includes(mint.toString()));
}
