import { useReducer } from "react";
import Royalties from "types/Royalties";
import isPublicKey from "utils/isPublicKey";
import sumArray from "utils/sumArray";

const ROYALTIES_DEFAULT = [{ address: "", share: 0 }];

export type RoyaltiesDispatchAction =
  | { type: "add_row" }
  | { type: "remove_row"; index: number }
  | { type: "set_directly"; value: Array<Royalties> }
  | { type: "update_address"; address: string; index: number }
  | { type: "update_share"; share: number; index: number };

function royaltiesReducer(
  state: Array<Royalties>,
  action: RoyaltiesDispatchAction
): Array<Royalties> {
  if (action.type === "add_row") {
    const result = [...state, { address: "", share: 0 }];
    return result;
  }

  if (action.type === "remove_row") {
    const result = state.filter((_, index) => index !== action.index);
    return result;
  }

  if (action.type === "set_directly") {
    return action.value;
  }

  if (action.type === "update_address") {
    const result = state.map((row, index) =>
      index === action.index
        ? { address: action.address, share: row.share }
        : row
    );
    return result;
  }

  if (action.type === "update_share") {
    const result = state.map((row, index) =>
      index === action.index
        ? { address: row.address, share: action.share }
        : row
    );
    return result;
  }

  throw new Error("should not reach");
}

export function isRoyaltiesValid(royalties: Array<Royalties>): boolean {
  const sum = sumArray(royalties.map(({ share }) => share));
  if (sum !== 100) {
    return false;
  }

  return royalties.every(
    (royaltiesItem) =>
      royaltiesItem.share > 0 && isPublicKey(royaltiesItem.address)
  );
}

export default function useRoyaltiesReducer() {
  return useReducer<typeof royaltiesReducer>(
    royaltiesReducer,
    ROYALTIES_DEFAULT
  );
}
