import { Context, createContext, useMemo, useState } from "react";

import * as anchor from "@project-serum/anchor";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { ThemeProvider, createTheme } from "@material-ui/core";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
} from "@solana/wallet-adapter-wallets";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import emptyFunction from "utils/emptyFunction";
import getRpcHostFromNetwork from "utils/getRpcHostFromNetwork";

const defaultNetwork = process.env
  .REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const defaultRpcHost = getRpcHostFromNetwork(defaultNetwork);
const defaultConnection = new anchor.web3.Connection(defaultRpcHost);
const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
  palette: {
    type: "dark",
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

export type SolanaContextData = {
  connection: anchor.web3.Connection;
  network: WalletAdapterNetwork;
  txTimeout: number;

  setNetwork: (val: WalletAdapterNetwork) => void;
};

export const SolanaContext: Context<SolanaContextData> =
  createContext<SolanaContextData>({
    connection: defaultConnection,
    network: defaultNetwork,
    txTimeout,

    setNetwork: emptyFunction,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function SolanaContextProvider(props: ProviderProps): JSX.Element {
  const [network, setNetwork] = useState(defaultNetwork);
  const endpoint = useMemo(() => getRpcHostFromNetwork(network), [network]);
  const connection = useMemo(
    () => new anchor.web3.Connection(getRpcHostFromNetwork(network)),
    [network]
  );

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      // If we add this, the "change network" feature + useAnchorWallet causes a bunch of re-renders
      // getSolletWallet({ network }),
      // getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <SolanaContext.Provider
              // eslint-disable-next-line react/jsx-no-constructed-context-values
              value={{
                connection,
                network,
                txTimeout,

                setNetwork,
              }}
            >
              {props.children}
            </SolanaContext.Provider>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
}
