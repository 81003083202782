import { programs } from "@metaplex/js";
import {
  Connection,
  PublicKey,
  TokenAccountBalancePair,
} from "@solana/web3.js";
import { Maybe } from "types/UtilityTypes";

/**
 * Returns true if token accounts are valid for an NFT mint.
 */
function isValidTokenAccountBalancePairArr(
  pairs: Array<TokenAccountBalancePair>
): boolean {
  const amountSum = pairs.reduce(
    (acc, currVal) => acc + (currVal.uiAmount ?? 0),
    0
  );

  return pairs.every((pair) => pair.decimals === 0) && amountSum === 1;
}

/**
 * @param connection
 * @param mintAddress the address of an NFT's mint account
 * @returns the token account that owns the NFT
 */
export default async function getNftMintTokenAccount(
  connection: Connection,
  mintAddress: PublicKey
): Promise<Maybe<programs.TokenAccount>> {
  try {
    const { value: pairs } = await connection.getTokenLargestAccounts(
      mintAddress
    );
    if (!isValidTokenAccountBalancePairArr(pairs)) {
      return null;
    }
    const tokenAccountsWithBalance = pairs.filter((val) => val.uiAmount === 1);

    const tokenAccountAddress = tokenAccountsWithBalance[0].address;
    const accountInfo = await connection.getAccountInfo(tokenAccountAddress);
    if (accountInfo == null) {
      return null;
    }

    return new programs.TokenAccount(tokenAccountAddress, accountInfo);
  } catch {
    return null;
  }
}
