import {
  WalletDialogButton,
  WalletDisconnectButton,
} from "@solana/wallet-adapter-material-ui";
import { useAnchorWallet } from "@solana/wallet-adapter-react";

export default function ConnectWalletButton(): JSX.Element {
  const wallet = useAnchorWallet();

  if (wallet != null) {
    return <WalletDisconnectButton />;
  }

  return <WalletDialogButton>Connect Wallet</WalletDialogButton>;
}
