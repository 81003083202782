import { programs } from "@metaplex/js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey } from "@solana/web3.js";
import { notify } from "components/toast/notifications";
import { Maybe } from "types/UtilityTypes";
import findAssociatedTokenAddress from "utils/pda/findAssociatedTokenAddress";
import getNftMintTokenAccount from "utils/solana/getNftMintTokenAccount";
import sendTransaction from "utils/solana/sendTransaction";

export default async function transferNft(
  connection: Connection,
  wallet: AnchorWallet,
  mintAddress: PublicKey,
  // wallet address, not token account address
  currentOwner: PublicKey,
  // wallet address, not token account address
  newOwner: PublicKey
): Promise<Maybe<string>> {
  const fromTokenAccount = await getNftMintTokenAccount(
    connection,
    mintAddress
  );
  if (fromTokenAccount == null) {
    notify({ type: "error", message: "Could not find token account" });
    return null;
  }

  const [ataAddress] = await findAssociatedTokenAddress(newOwner, mintAddress);

  const transferIx = Token.createTransferInstruction(
    TOKEN_PROGRAM_ID,
    fromTokenAccount.pubkey,
    ataAddress,
    currentOwner,
    [],
    1
  );

  const tx = new programs.Transaction();
  const ataAccountInfo = await connection.getAccountInfo(ataAddress);
  if (ataAccountInfo == null) {
    // Only create ATA if it doesn't exist already
    const ataIx = Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mintAddress,
      ataAddress,
      newOwner,
      currentOwner
    );
    tx.add(ataIx);
  }

  tx.add(transferIx);

  const txid = await sendTransaction({
    connection,
    wallet,
    txs: [tx],
    signers: [],
    options: {
      preflightCommitment: "confirmed",
    },
  });
  if (txid == null) {
    return null;
  }

  notify({ message: "Successfully transferred NFT", txid });
  await connection.getParsedConfirmedTransaction(txid, "confirmed");

  return txid;
}
