import styles from "css/header/HeaderDesktop.module.css";
import ConnectWalletButton from "components/buttons/ConnectWalletButton";
import TextButton from "components/buttons/TextButton";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Header1 from "components/text/Header1";
import Header2 from "components/text/Header2";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import GlobalClass from "types/enums/GlobalClass";
import ChooseNetworkButton from "components/buttons/ChooseNetworkButton";

export default function HeaderDesktop(): JSX.Element {
  return (
    <ResponsiveContainer>
      <Header1 className={styles.header} textAlign="center">
        <div className={styles.left}>
          <a className={GlobalClass.HideText} href="/">
            <Header2 colorClass={ColorClass.Navy}>Nifty Minter</Header2>
          </a>
        </div>
        <div className={styles.right}>
          <TextButton
            className={styles.left}
            fontClass={FontClass.Body1}
            href="/airdrop"
            type="link_internal"
          >
            Airdrop
          </TextButton>
          <TextButton
            className={styles.left}
            fontClass={FontClass.Body1}
            href="/mint"
            type="link_internal"
          >
            Mint Master
          </TextButton>
          <TextButton
            className={styles.left}
            fontClass={FontClass.Body1}
            href="/mint-standard"
            type="link_internal"
          >
            Mint Standard
          </TextButton>
          <ChooseNetworkButton />
          <ConnectWalletButton />
        </div>
      </Header1>
    </ResponsiveContainer>
  );
}
