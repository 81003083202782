import { PublicKey } from "@solana/web3.js";
import ButtonWithText from "components/buttons/ButtonWithText";
import ResponsiveContainer from "components/containers/ResponsiveContainer";
import Header from "components/header/Header";
import TextInput from "components/input/TextInput";
import styles from "css/pages/view-holders/ViewHoldersPage.module.css";
import useSolanaContext from "hooks/useSolanaContext";
import { useState } from "react";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import isPublicKey from "utils/isPublicKey";
import findHolders from "utils/metaplex/findHolders";

export default function ViewHoldersPage(): JSX.Element {
  const { connection } = useSolanaContext();
  // TODO: change default back to empty string
  const [mintAddress, setMintAddress] = useState(
    "Afm9Vgdi2Lh8GygnqDBzJSShYxC8YBHvN8iyxc2CXywi"
  );
  const [holders, setHolders] = useState<Array<PublicKey>>([]);

  return (
    <div>
      <Header />
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <div className={styles.form}>
            <TextInput
              label="Mint Address"
              onChange={setMintAddress}
              value={mintAddress}
            />
            <ButtonWithText
              buttonTheme={ButtonTheme.Navy}
              className={styles.submitButton}
              fontClass={FontClass.Body1}
              onClick={async () => {
                if (!isPublicKey(mintAddress)) {
                  // TODO: set error
                  return;
                }

                setHolders([]);
                const holdersInner = await findHolders(
                  connection,
                  new PublicKey(mintAddress)
                );
                setHolders(holdersInner ?? []);
              }}
            >
              Submit
            </ButtonWithText>
          </div>
          <pre className={styles.holders}>
            {JSON.stringify(
              holders.map((key) => key.toString()),
              null,
              2
            )}
          </pre>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
