import { programs } from "@metaplex/js";
import { Connection, PublicKey } from "@solana/web3.js";
import { Maybe } from "types/UtilityTypes";

export default async function getMetadataAccountFromMint(
  connection: Connection,
  mintAddress: PublicKey
): Promise<Maybe<programs.metadata.Metadata>> {
  try {
    const metadataAddress = await programs.metadata.Metadata.getPDA(
      mintAddress
    );
    return programs.metadata.Metadata.load(connection, metadataAddress);
  } catch {
    return null;
  }
}
